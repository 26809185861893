  //var _cookieDomain = "www.cconmausa.com";
  var _cookieDomain = "cconmausa.com";
  var _mtr_cname = "MTRRKID";
  var _mtr_cperiod = 7; // day
//var _mtr_cperiod = 10; // min
  var _mtr_str = "";
  var _mtr_expire = "";
  //var _mtr_rk_touch_url = "https://us05.cconmausa.com/mtr/rk/touch";
  var _mtr_rk_touch_url = "https://cusca3.cconmausa.com/mtr/rk/touch";

  document.domain = "cconmausa.com";

  function setCookie(cName, cValue, cDay)
  {
    cookies = cName + '=' + escape(cValue);

    if(typeof cDay != 'undefined')
    {
      var expire = new Date();
      expire.setDate(expire.getDate() + cDay);
//expire.setMinutes(expire.getMinutes() + cDay);
      cookies += ';expires=' + expire.toGMTString();
_mtr_expire = expire.toGMTString();
    }

    if(typeof _cookieDomain != 'undefined')
    {
      cookies += ';domain=' + _cookieDomain;
    }

    cookies += ';path=/';

    document.cookie = cookies;
  }

  // Moved to common.js
  /*function getCookie(cName)
  {
    var cookieData = document.cookie;

    var cNameEq = cName + '=';
    var cValue = '';
    var sidx = cookieData.indexOf(cNameEq);
    if(sidx != -1)
    {
      sidx += cNameEq.length;
      var eidx = cookieData.indexOf(';', sidx);
      if(eidx == -1)
      {
        eidx = cookieData.length;
      }
      cValue = cookieData.substring(sidx, eidx);
      cValue = unescape(cValue);
    }

console.log("cValue:|" + cValue + "|");
    return cValue;
  }*/

  function isMtrLoaded()
  {
    if(_mtr_str == "")
    {
      return false;
    }
    if(_mtr_str == null)
    {
      return false;
    }

    return true;
  }

  function loadMtr()
  {
    if(isMtrLoaded())
    {
      return true;
    }

    _mtr_str = getCookie(_mtr_cname);

    if(_mtr_str == "")
    {
      return false;
    }

    return true;
  }

  function initMtr()
  {
    if(!isMtrLoaded())
    {
      loadMtr();
    }

    if(!isMtrLoaded())
    {
      initForAccessHomeByRkReferer();

      return true;
    }

    // [NOTE] Do not extend
    //extendMtr();

    touch_mtr_ajax();

    /*if(isMtrUrl())
    {
      enableMtr();
    }*/

    /*var href = window.location.href;
    var cart_url = "https://www.cconmausa.com/cart";

    if(href.substr(0, cart_url.length) == cart_url)
    {
      setRKTrackCartAttribute();
    }*/
  }

  /*function initMtrForHomeTemplate()
  {
    if(!isMtrLoaded())
    {
      return initForAccessHomeByRkReferer();
    }

    return true;
  }*/

  function extendMtr()
  {
    if(!isMtrLoaded())
    {
      return false;
    }

    setCookie(_mtr_cname, _mtr_str, _mtr_cperiod);
  }

  function setRKTrackCartAttribute()
  {
    //alert("setRKTrackCartAttribute()");
    //console.log("setRKTrackCartAttribute()");
  }

  function touch_mtr_ajax()
  {
    $.ajax
    ({
      type		: "POST",
      //url		: _extapi_url,
      url		: "/apps/extapi/jsapi",
      data		:
      {
        action: "touch_mtr",
        mtr: _mtr_str,
        expire: _mtr_expire,
        /*cid: __cid,*/
        mtrval: encodeURIComponent(__mtrval)
      },
      cache		: false,
      dataType	: "json",
      async		: true,
      success		: function(jsonData)
      {
        if(jsonData.result_code != null)
        {
          // OK
          console.log("touch_mtr success");
        }
      },
      error		: function(XMLHttpRequest, textStatus, errorThrown)
      {
        /*alert("[touch] Error code : " + XMLHttpRequest.status + "\n" +
              "message : " + XMLHttpRequest.responseText + "\n" +
              "text status : " + textStatus + "\n" +
              "error : " + errorThrown);*/
          console.log("[mrt touch exception] Error code : " + XMLHttpRequest.status + "\n" +
              "message : " + XMLHttpRequest.responseText + "\n" +
              "text status : " + textStatus + "\n" +
              "error : " + errorThrown);
      }
    });
  };

  /*function isMtrUrl()
  {
    var mtr_param = getQueryStringParameterByName("mtr");

    if(mtr_param != "")
    {
      return true;
    }

    return false;
  }

  function enableMtr()
  {
    var mtr_param = getQueryStringParameterByName("mtr");

var cValue = "___";
    setCookie(_mtr_cname, cValue, _mtr_cperiod);

    //loadMtr();
    _mtr_str = cValue;
  }*/

  function initForAccessHomeByRkReferer()
  {
    var cur_url = window.location.href;

    // Check if current url is Home
    if(cur_url != "https://www.cconmausa.com" &&
       cur_url != "https://www.cconmausa.com/")
    {
      return true;
    }

    // Check if referer is RK
    var cur_referer = document.referrer;
    // http://www.radiokorea.com/ads/?page=WING&&ads=HOME&pos=R02
console.log("ref:" + cur_referer);
    var _token = cur_referer.split("/");
    if(_token.length < 3 || _token[2].length <= 0)
    {
      return false;
    }
    var referer_domain = _token[2];
    var _token2 = referer_domain.split(".");
    if(_token2.length < 2)
    {
      return false;
    }
    if(_token2[_token2.length - 1] != "com" ||
       _token2[_token2.length - 2] != "radiokorea")
    {
      return true;
    }

    if(_mtr_str.length > 0)
    {
      return false;
    }

    _mtr_str = "_rk_referer_set_";

    if(typeof __mtrval != 'undefined')
    {
      if(__mtrval.length > 0)
      {
        __mtrval += "&";
      }
      __mtrval += "init_referer=" + encodeURIComponent(cur_referer);
    }

    //touch_mtr_ajax();
    /*document.write(
      "<iframe name=_frm_mtr" +
      " src='https://www11.cconmausa.com/mtr/rk/touch?__mtrval=" +
      encodeURIComponent("init_referer=" + encodeURIComponent(cur_referer)) + "'" +
      " style='height:0;width:0;border:0;border:none;visibility:hidden;'>" +
      "</iframe>");
console.log(
      "<iframe name=_frm_mtr" +
      " src='https://www11.cconmausa.com/mtr/rk/touch?__mtrval=" +
      encodeURIComponent("init_referer=" + encodeURIComponent(cur_referer)) + "'" +
      " style='height:0;width:0;border:0;border:none;visibility:hidden;'>" +
      "</iframe>");*/

    var mtr_iframe_src =
      _mtr_rk_touch_url + "?__mtrval=" +
      encodeURIComponent("init_referer=" + encodeURIComponent(cur_referer));
    var mtr_iframe_obj =
        $("<iframe/>", {
          name : "_frm_mtr",
          src : mtr_iframe_src,
          style : "height:0;width:0;border:0;border:none;visibility:hidden;"
        });
    mtr_iframe_obj.hide();

    $("body").append(mtr_iframe_obj);

    _mtr_str = "";
    __mtrval = "";
    
    setTimeout(function()
    {
//console.log("[after iframe] cookie : " + getCookie(_mtr_cname));
//console.log("[after iframe] _mtr_str : " + _mtr_str);

      loadMtr();
//console.log("[after loadMtr()] cookie : " + getCookie(_mtr_cname));
//console.log("[after loadMtr()] _mtr_str : " + _mtr_str);
console.log("_mtr_str : |" + _mtr_str + "|");

      touch_mtr_ajax();
    }, 1000);

    return true;
  }

  $(document).ready(function()
  {
    initMtr();
console.log("_mtr_str:|" + _mtr_str + "|");
  });